<template>
  <v-card hover
          @click="selectItem(item)">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h4 font-weight-bold">
          <v-tooltip right>
            <template #activator="{on}">
              <span v-on="on">
                {{ item.serviceRequestNumber }}
              </span>
            </template>
            <span>Service Request Number</span>
          </v-tooltip>
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption">
          {{ item.serviceType.value }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>{{ item.createdTimestamp }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-col class="py-0">
            <v-tooltip v-if="item.externalReferenceStatus"
                       top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        color="grey darken-3"
                        outlined
                        v-on="on">
                  {{ item.externalReferenceStatus }}
                </v-chip>
              </template>
              <span>External Status</span>
            </v-tooltip>
            <v-tooltip v-else
                       top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        color="grey darken-3"
                        outlined
                        v-on="on">
                  {{ item.status.value }}
                </v-chip>
              </template>
              <span>Status</span>
            </v-tooltip>
          </v-col>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getSeverityColor(item.severity)"
                        v-on="on">
                  {{ item.severity | startCase }}
                </v-chip>
              </template>
              <span>Severity</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pt-0">
      <v-row>
        <v-col cols="12"
               sm="3">
          <v-tooltip top>
            <template #activator="{on}">
              <div class="text-body-1 font-weight-bold"
                   v-on="on">
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ item.customer.name | startCase }}
              </div>
            </template>
            <span>Customer</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1"
               class="text-center">
          <v-divider vertical />
        </v-col>
        <v-col cols="12"
               sm="3">
          <v-tooltip top>
            <template #activator="{on}">
              <div class="text-body-1 font-weight-bold text-center"
                   v-on="on">
                <v-icon left>
                  mdi-card-bulleted-outline
                </v-icon>
                {{ item.vehicle.registrationNumber }}
              </div>
            </template>
            <span>Vehicle Registration Number</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="item.externalReferenceId"
               cols="1"
               class="text-center">
          <v-divider vertical />
        </v-col>
        <v-col v-if="item.externalReferenceId"
               cols="12"
               sm="3">
          <v-tooltip top>
            <template #activator="{on}">
              <div class="text-body-1 font-weight-bold text-center"
                   v-on="on">
                <v-icon left>
                  mdi-ticket-confirmation
                </v-icon>
                {{ item.externalReferenceId }}
              </div>
            </template>
            <span>AA Case ID</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-tooltip top>
            <template #activator="{on}">
              <div class="text-body-1 font-weight-bold"
                   v-on="on">
                <v-icon left>
                  mdi-map-marker-distance
                </v-icon>
                {{ item.currentTask }}
              </div>
            </template>
            <span>Current Task</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { SEVERITY_COLORS, SERVICE_TYPE_LIST } from '@/config/constants';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    getSeverityColor(severity) {
      return SEVERITY_COLORS[severity];
    },

    selectItem(item) {
      if (item.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key) {
        this.$store.commit('serviceRequests/showServiceRequestTasks', false);
      } else {
        this.$store.commit('serviceRequests/showServiceRequestTasks', true);
      }

      this.$router.push({
        name: 'SlaManagementServiceRequest',
        params: {
          serviceRequestNumber: item.serviceRequestNumber,
        },
      });
    },
  },
};
</script>
