var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"hover":""},on:{"click":function($event){return _vm.selectItem(_vm.item)}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 font-weight-bold"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.item.serviceRequestNumber)+" ")])]}}])},[_c('span',[_vm._v("Service Request Number")])])],1),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.item.serviceType.value)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.createdTimestamp))])],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[(_vm.item.externalReferenceStatus)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":"grey darken-3","outlined":""}},on),[_vm._v(" "+_vm._s(_vm.item.externalReferenceStatus)+" ")])]}}],null,false,1752730863)},[_c('span',[_vm._v("External Status")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":"grey darken-3","outlined":""}},on),[_vm._v(" "+_vm._s(_vm.item.status.value)+" ")])]}}])},[_c('span',[_vm._v("Status")])])],1),_c('v-col',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":_vm.getSeverityColor(_vm.item.severity)}},on),[_vm._v(" "+_vm._s(_vm._f("startCase")(_vm.item.severity))+" ")])]}}])},[_c('span',[_vm._v("Severity")])])],1)],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 font-weight-bold"},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(_vm._f("startCase")(_vm.item.customer.name))+" ")],1)]}}])},[_c('span',[_vm._v("Customer")])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 font-weight-bold text-center"},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-card-bulleted-outline ")]),_vm._v(" "+_vm._s(_vm.item.vehicle.registrationNumber)+" ")],1)]}}])},[_c('span',[_vm._v("Vehicle Registration Number")])])],1),(_vm.item.externalReferenceId)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1):_vm._e(),(_vm.item.externalReferenceId)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 font-weight-bold text-center"},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-ticket-confirmation ")]),_vm._v(" "+_vm._s(_vm.item.externalReferenceId)+" ")],1)]}}],null,false,1453595959)},[_c('span',[_vm._v("AA Case ID")])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 font-weight-bold"},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-map-marker-distance ")]),_vm._v(" "+_vm._s(_vm.item.currentTask)+" ")],1)]}}])},[_c('span',[_vm._v("Current Task")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }